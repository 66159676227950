
import { baseUrl } from "../../constants";

export default {
    "formTitle": "Add Work Center",
    "tableTitle": "Work Center List",
    "apiDetails": {
        startUp: () => {
        },
        list: {
            url: `${baseUrl}/api/mms/work_center/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "id"
            }
        },
        add: `${baseUrl}/api/mms/work_center`,
        edit: `${baseUrl}/api/mms/work_center/edit`,
        delete: `${baseUrl}/api/mms/work_center/remove`
    },
    "tableCol": [
        {
            name: 'Work Center',
            selector: row => row.name,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Work Center',
                    name: 'name',
                    "required": true
                }
            ]
        }
    ],
    "mockData": [{
        "name": "12"
    }]
}