import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Forms from '../workorder/form';
import Tableworkorder from '../workorder/table';
import HeaderMMS from '../header/header';
import MenuMMS from '../menu/menu';
import { setFullLoad, setSnackMsg } from "../../redux/actions/common";
import { baseUrl } from "../../constants";
import { getUserProfile } from '../../redux/selector';

import { fetchDropDown, debounce } from "../../utils";
import {
    getDDFacilityCall, getDDEquCall, getDDSubEquCall,
    getDDDeviceCall, getDDJobProCall, getDDJobNatureCall,
    getDDWorkCall, getDDEmployeeCall, getDDJobStatusCall,
    getDDTagCall, getDDWorkCenterCall, getDDTaskCall,
    getDDDeptCall,
    getDDOrderTypeCall
} from "../../redux/actions/dropDown";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{
                width: "calc(100vw - 155px)"
            }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
export default function Settings() {
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [tableData, setTableData] = React.useState([]);
    const dispatch = useDispatch();
    const userDetails = useSelector(getUserProfile);

    const fetchTableData = (payload) => {
        dispatch(setFullLoad(true));
        console.log(userDetails);
        const url = `${baseUrl}/api/work/daily_log/list`;
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload || {}),
            headers: { "Content-type": "application/json; charset=UTF-8" }
        })
            .then((response) => {
                return response.json();
            })
            .then(({ data = [] }) => {
                setTableData(data || []);
                dispatch(setFullLoad(false));
            });
    };

    React.useEffect(() => {
        getDD();
        fetchTableData();
    }, []);

    const getDD = React.useCallback(
        debounce(() => {
            fetchDropDown(dispatch, [
                getDDFacilityCall, getDDEquCall, getDDSubEquCall,
                getDDDeviceCall, getDDJobProCall, getDDJobNatureCall,
                getDDWorkCall, getDDEmployeeCall, getDDJobStatusCall,
                getDDTagCall, getDDWorkCenterCall, getDDTaskCall,
                getDDDeptCall, getDDOrderTypeCall
            ]);
        }, 200),
        []
    );

    const handleClose = () => {
        setOpenDialog(false);
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const headerProps = {
        open, handleDrawerOpen
    }
    const menuProps = {
        setOpen, open
    }
    const tableProps = {
        setOpenDialog,
        tableData,
        userDetails,
        WOType: 'daily'
    }
    const [formData, setFormData] = useState({
        slno: '',
        dateofpurchase: null,

    });
    const [fields, setFields] = useState([{ value: '' }]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleDateChange = (date, field) => {
        setFormData({
            ...formData,
            [field]: date
        });
    };
    const handleSubmitForm = () => {
        console.log(formData);
    }
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    return (
        <Box sx={{ display: 'flex' }}>

            <HeaderMMS {...headerProps} />
            <MenuMMS {...menuProps} />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className='bg-slate-100'>
                <Grid className='bg-slate-100 '>
                    <DrawerHeader />
                    {/* <Forms /> */}
                    <div className='rounded p-3 text-lg text-left font-semibold'>
                        <h2>Daily Log Reports</h2>
                    </div>

                    <div className="mms-report-sec">

                        <div className='text-left bg-white  m-2 border-solid border rounded'>
                            <h1 className='font-bold text-center'>Warrenty Reports</h1>
                            <div className="mms-date-field">
                                <Grid container xs={12} sm={12} className='items-start'>
                                    <Grid item xs={12} md={12} container>



                                        <Grid item xs={12} sm={3} className='p-3 '>
                                            <TextField size='small' label="PO Number" variant="outlined"
                                                name="poNumber"
                                                value={formData.poNumber}
                                                className='w-full'
                                                onChange={handleInputChange} />
                                        </Grid>



                                        <Grid item xs={12} sm={3} className='p-3'>
                                            <LocalizationProvider label="Date" dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="PO Date"
                                                    value={formData.dateinvoicenum}
                                                    className='w-full'
                                                    onChange={(date) => handleDateChange(date, 'dateinvoicenum')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>





                                        <Grid item xs={12} sm={3} className='p-3 '>
                                            <TextField size='small' label="Invoice Number" variant="outlined" name="invoiceNumber"
                                                value={formData.invoiceNumber}
                                                className='w-full'
                                                onChange={handleInputChange} />
                                        </Grid>




                                        <Grid item xs={12} sm={3} className='p-3 '>
                                            <LocalizationProvider label="Date" dateAdapter={AdapterDayjs} className="mms-date-field">
                                                <DatePicker
                                                    label="Invoice Date"
                                                    value={formData.dateofpurchase}
                                                    className='w-full'
                                                    onChange={(date) => handleDateChange(date, 'dateofpurchase')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>


                                        </Grid>






                                        <Grid item xs={12} sm={3} className='p-3 '>
                                            <TextField size='small' label="Warrenty  Start" variant="outlined" name="warrentystart"
                                                value={formData.warrentystart}
                                                className='w-full'
                                                onChange={handleInputChange} />

                                        </Grid>


                                        <Grid item xs={12} sm={3} className='p-3 '>
                                            <TextField size='small' label="Warrenty  End" variant="outlined" name="warrentyend"
                                                value={formData.warrentyend}
                                                className='w-full'
                                                onChange={handleInputChange} />
                                        </Grid>






                                        <Grid item xs={12} sm={3} className='p-3 '>
                                            <TextField size='small' label=" Warrenty Period" variant="outlined"
                                                name="warrentyPeriod"
                                                className='w-full'
                                                value={formData.warrentyPeriod}
                                                onChange={handleInputChange} />
                                        </Grid>


                                        <Grid item xs={12} sm={3} className='p-3 '>
                                            <TextField size='small' label="Expiry Notification" variant="outlined"
                                                name="expiryNotification"
                                                className='w-full'
                                                value={formData.expiryNotification}
                                                onChange={handleInputChange} />

                                        </Grid>




                                        <Grid item xs={12} sm={3} className='p-3 '>
                                            <TextField size='small' label="Vendor/Supplier" variant="outlined" name="vendorSupplier"
                                                value={formData.vendorSupplier}
                                                className='w-full'
                                                onChange={handleInputChange} />
                                        </Grid>




                                        <Grid item xs={12} sm={3} className='p-3 '>
                                            <TextField size='small' label="Contact Name " variant="outlined"
                                                name="contactName"
                                                value={formData.contactName}
                                                className='w-full'
                                                onChange={handleInputChange} />
                                        </Grid>





                                        <Grid item xs={12} sm={3} className='p-3 '>
                                            <TextField size='small' label=" Contact number" variant="outlined"
                                                name="contactNumber"
                                                value={formData.contactNumber}
                                                className='w-full'
                                                onChange={handleInputChange} />
                                        </Grid>



                                        <Grid item xs={12} sm={3} className='p-3 '>
                                            <TextField size='small' label="Contact E-mail" variant="outlined"
                                                name="contactEmail"
                                                value={formData.contactEmail}
                                                className='w-full'
                                                onChange={handleInputChange} />

                                        </Grid>
                                        <Grid item xs={12} sm={10} className='p-3 '>
                                            <button class="bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded inline-flex items-center">Submit</button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} container>
                                        <Grid item xs={12} sm={12} className='p-3 '>
                                            <div class="space-x-4 ml-auto float-right">

                                                <button class="bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                                                    <PictureAsPdfIcon></PictureAsPdfIcon>

                                                    PDF
                                                </button>


                                                <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                                                    <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 18L22 12m0 0l-6-6m6 6H2" />
                                                    </svg>
                                                    CSV
                                                </button>


                                                <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                                                    <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16m-7 4h7" />
                                                    </svg>
                                                    Excel
                                                </button>
                                            </div>
                                        </Grid>

                                        <CustomTabPanel value={0} index={0}>
                                            <Box className='bg-slate-100 mms-table-sec'>
                                                <Tableworkorder {...tableProps} />

                                            </Box>
                                        </CustomTabPanel>
                                    </Grid>
                                </Grid>
                                <BootstrapDialog
                                    className="mms-full-dig"
                                    onClose={handleClose}
                                    aria-labelledby="customized-dialog-title"
                                    open={openDialog?.formType}
                                >
                                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='bg-sky-600 text-white'>
                                        Edit Daily Log Reques
                                    </DialogTitle>
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleClose}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <DialogContent dividers>
                                        <Forms
                                            handleClose={handleClose}
                                            formDataObj={openDialog}
                                            fetchTableData={fetchTableData}
                                            WOType={'daily'}
                                            userDetails={userDetails}
                                        />
                                    </DialogContent>
                                    <DialogActions>

                                    </DialogActions>
                                </BootstrapDialog>
                            </div>
                        </div>
                    </div>






                </Grid>
            </Box>
        </Box>
    );

}