import * as React from "react";
import loginbg from '../Assets/loginbg.png';
import { useNavigate } from "react-router-dom";
import { setProfileAction } from "../redux/actions/profile";
import { useDispatch } from "react-redux";
import { setCookie } from "../utils";
import { setSnackMsg, setFullLoad } from "../redux/actions/common";
import { baseUrl } from "../constants";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUserName] = React.useState('');
  const [userPwd, setUserPwd] = React.useState('');
  const dashboard=(e)=>{
    e.preventDefault();
    dispatch(setFullLoad(true));
    fetch(`${baseUrl}/api/mmsprofile/authentication?username=${userName}&password=${userPwd}`, {
      method: "POST",
      // body: JSON.stringify({ username: userName, password: userPwd }),
      // headers: { "Content-type": "application/json; charset=UTF-8" }
    })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      const { data = [], message } = res || {};
      if( data ){
        dispatch(setProfileAction(data?.[0]));
        setCookie('mmsUser', JSON.stringify(data?.[0]), 0.1);
        navigate("/dashboard");
      }
      else{
        dispatch(setSnackMsg({
            open: true,
            error: true,
            msg: message
        }));
      }
      dispatch(setFullLoad(false));
    });
  }
    return (
      <>
    
        <div className="flex">
            <div className="hidden lg:block lg:w-1/2 bg-cover">
            <img src={loginbg} alt="" className="loginbg" />
            </div>
            <div className="w-full p-8 lg:w-1/2">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          
            <h2 className="mt-10 text-left text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Login
            </h2>
          </div>
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                  User Name
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="text"
                    autoComplete="email"
                    onChange={(e)=>{ setUserName(e.target.value); }}
                    className="block font-medium pl-2 w-full rounded-3xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="text-sm">
                    <a href="#" className="font-semibold text-indigo-600 hover:underline ">
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e)=>{ setUserPwd(e.target.value); }}
                    className="block pl-2 w-full font-medium rounded-3xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            <div>
            </div>
              <div>
                <button
                onClick={dashboard}
                  type="submit"
                  className="flex w-full justify-center rounded-3xl bg-sky-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>
            </div>
          </div>
        </div>
      </>
    )
  }
  